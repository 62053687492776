import React from 'react'
import './footer.css'

function footer() {
  return (
    <div id='contact' className='footer' >
      <div className='foot main' >
      <h2>اسبوع الجودة العالمي</h2>
      <p className='title' ><span>2024</span> العراق</p>

      <p>تواصل معنا</p>

      <p>Phone <span>+964 7716961346</span></p>
      <p>Phone <span>+964 7838200050</span></p>

      <p>Email <span>sales@firewall-iq.com
      </span></p>

      <div className='footer-links' >

      </div>
      </div>
      <div className='foot section' >
        <h3>وصول سريع</h3>
        <a href="#home">الرئيسية</a>
        <a href="#about">حول</a>
        <a href="#conference">الملتقى</a>
      </div>
    </div>
  )
}

export default footer