import React from 'react'
import './official.css'
import letterimg from '../../images/import.jfif'

function official() {
  return (
    <div className='official-container' >
      <h1>الموافقات الرسمية</h1>
      <hr className='official-hr' />
      <div className='official-content' >
        <img src={letterimg} alt="" />
      </div>
    </div>
  )
}

export default official