import React, { useEffect } from 'react';
import './home.css';
import Assets from '../../images/logo2.png';
import word1 from '../../images/word.png';
import word2 from '../../images/word2.png';

function Home() {
  useEffect(() => {
    // Load the countdown timer script
    const script = document.createElement('script');
    script.id = 'tickcounter-sdk';
    script.src = "//www.tickcounter.com/static/js/loader.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id='home' className='home-container'>
      <div className='home-content'>
        <div className="img-holder">
          <img src={word1} alt="word1" className='word1'/>
          <img src={Assets} alt="logo2" className='logo2'/>
          <img src={word2} alt="word2" className='word2'/>
        </div>
        <h1>برعاية</h1>
        <h1>السيد رئيس مجلس الوزراء</h1>
        <h1>تقيم الامانة العامة لمجلس الوزراء</h1>
        <span className='Special'>
          <h2>الملتقى الاول لاسبوع الجودة <span className='specialfont'>العالمي</span></h2>
          <h2><span className='specialfont'>العراق</span> 2024</h2>
        </span>
        <a
          data-type="countdown"
          data-id="5732715"  // Updated countdown ID
          className="tickcounter"
          style={{
            display: 'block',
            left: 0,
            width: '100%',
            height: 0,
            position: 'relative',
            paddingBottom: '25%',
            margin: '0 auto',
          }}
          title="My countdown"
          href="//www.tickcounter.com/"
        >
          My countdown
        </a>
      </div>
      <div className='home-footer'>
        <button>رعاة الملتقى</button>
        <button>النشاطات</button>
        <button>معرض الصور</button>
        <button>المشاركين</button>
      </div>
    </div>
  );
}

export default Home;
