import React, { useState } from 'react';
import './sectors.css';
import Logos from '../../Sectorlogo.json';
import LazyLoad from 'react-lazyload';

function Sectors() {
  const [selectedSector, setSelectedSector] = useState(null);

  const handleBoxClick = (sectorId) => {
    setSelectedSector(sectorId);
  };

  const handleClose = () => {
    setSelectedSector(null);
  };

  // Find the selected sector's data
  const selectedSectorData = Logos.find(sector => sector.id === selectedSector);

  return (
    <div className='sectors-container'>
      <h1>المشاركين</h1>
      <hr />
      <div className="sectors-content">
        {Logos.map((sector) => (
          <div key={sector.id}>
            <div 
              className='box' 
              onClick={() => handleBoxClick(sector.id)}
            >
              <LazyLoad offset={100} className="icon2">
                <img src={sector.path} alt=' ' className="icon" id={`icon${sector.id}`} />
              </LazyLoad>
              <h3>{sector.name}</h3>
            </div>
          </div>
        ))}
      </div>
      {selectedSectorData && (
        <div className='overlay' onClick={handleClose}>
          <div className='Box-content' onClick={(e) => e.stopPropagation()}>
            <span className='close-btn' onClick={handleClose}>X</span>
            <h2>{selectedSectorData.name}</h2>
            <img src={selectedSectorData.path} alt={selectedSectorData.name} />
            <p>{selectedSectorData.description}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sectors;
