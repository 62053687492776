import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './navbar.css';
import Logo from '../../images/LOGO ONLY.png';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <nav className='navbar-container'>
        <div className='title'>
          <h2>الملتقى الاول لاسبوع الجودة </h2>
          <span> العالمي <p> العراق 2024 </p></span>
        </div>
        <div className='links3'>
        <Link className='Navlink' to="home">الرئيسية</Link>
        <div className='dropdown'>
          <p className='dropdown-toggle'>حول</p>
          <div className='dropdown-content'>
            <Link to="about">عنا</Link>
            <Link to="world-quality">World Quality</Link>
            <Link to="sectors">القطاعات</Link>
            <Link to="contact">تواصل معنا</Link>
          </div>
        </div>
        <Link className='Navlink' to="sponsorship">الرعاة</Link>
        <Link classNmae='Navlink' to=''>المشاركين</Link>
        <Link className='Navlink' to="contact">تواصل معنا</Link>
        </div>
        <div className='link-btns'>
          <img src={Logo} alt="" />
        </div>
      </nav>

      <div className='mobile-nav'>
        <button className='menu-toggle' onClick={toggleMenu}>
          ☰
        </button>
        <div className={`side-menu ${menuOpen ? 'open' : ''}`}>
        <button className='menu-toggle2' onClick={toggleMenu}>
          X
        </button>
        <div className='links1'>
          <Link onClick={toggleMenu} to="home">الرئيسية</Link>
          <Link onClick={toggleMenu} to="about">عنا</Link>
          <Link onClick={toggleMenu} to="world-quality">World Quality</Link>
          <Link onClick={toggleMenu} to="sectors">القطاعات</Link>
          <Link onClick={toggleMenu} to="sponsorship">الرعاة</Link>
          <Link onClick={toggleMenu} to="contact">تواصل معنا</Link>
          <Link onClick={toggleMenu} to=''>المشاركين</Link>
        </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
