import React from 'react'
import './eventLoc.css'
import place from '../../images/gg3.jpg'
import barcode from '../../images/create.png'

function eventLoc() {
  return (
    <div className='eventLoc-container' >
      <h1>عنوان الحدث</h1>
      <hr />
      <div className='eventLoc-content' >
      <div className='map-container'>
        <p>scan me</p>
        <img src={barcode} alt="" className='BARCODE' />
      </div>
      <div className='placePhoto' >
        <img src={place} alt=""/>
        <p>العراق - بغداد - معرض بغداد - قاعة الVIP</p>
      </div>
      </div>
    </div>
  )
}

export default eventLoc