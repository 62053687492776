import React,{useState} from 'react'
import './parteners.css'
import Logo from '../../Parteners.json'

function parteners() {
  
  return (
    <div id='sponsorship' className='parteners-container' >
      <div className="Official container">
        <h1>الجهات المنظمة</h1>
        <hr />
        <div className='content'>
          {Logo.map(logo =><div className='logo-holder'
          ><img className='partenersLogo' src={logo.picture} alt={logo.name} key={logo.id}/></div>)}
        </div>
      </div>
      <div className="plat container">
        <h1>داعم بلاتيني</h1>
        <hr />
        <div className='content'>

        </div>
      </div>
      <div className="gold container">
        <h1>داعم ذهبي</h1>
        <hr />
        <div className='content'>

        </div>
      </div>
      <div className="silver container">
        <h1>داعم فضي</h1>
        <hr />
        <div className='content'>

        </div>
      </div>
      <div className="strat container">
        <h1>شركاء اداريين</h1>
        <hr />
        <div className='content'>

        </div>
      </div>
    </div>
  )
}

export default parteners