import './App.css';
import React from 'react';
import Sectors from './components/sectors/sectors';
import Home from './components/home/home';
import Navbar from './components/navbar/navbar';
import About from './components/about/About';
import Footer from './components/footer/footer';
import Parteners from './components/parteners/parteners';
import Official from './components/official_letter/official';
import EventLoc from './components/event_location/eventLoc';

function App() {
  
  return (
    <div className='app-container'>
      <Navbar />
      <Home />
      <Official />
      <Sectors />
      <About />
      <Parteners />
      <EventLoc />
      <Footer />
    </div>
  );
}

export default App;
