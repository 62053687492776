import React from 'react'
import './About.css'

function About() {
  return (
    <div id='about' className='about-container'>

      <div className="about-content">
        <div>
        <h1>عن يوم الجودة العالمي</h1>
        <hr />
        <p className='about-intro' >
يوم الجودة العالمي هو مناسبة هامة تُحتفل بها المؤسسات والأفراد في جميع أنحاء العالم لزيادة الوعي بمفاهيم الجودة وتحسين الأداء في مختلف المجالات. يُحتفل بهذا اليوم في الخميس الثاني من شهر نوفمبر من كل عام، ويهدف إلى تسليط الضوء على أهمية الجودة كعنصر أساسي في تحقيق التفوق والابتكار .  

ومنذ انطلاقه، تطور يوم الجودة العالمي ليصبح فرصة ثمينة لتبادل الأفكار والممارسات الرائدة التي تسهم في تعزيز ثقافة الجودة وتحقيق نتائج مستدامة. في هذا التقرير، سنستعرض مفهوم يوم الجودة العالمي، تاريخه، أهميته، وسنقدم أفكاراً مميزة لفعاليات يمكن تنظيمها للاحتفال بهذا اليوم وتعزيز الوعي بالجودة .
          </p>
        </div>
          <div className='about-importance'>
          <h1>أهمية يوم الجودة العالمي </h1>
          <hr />
          <p  > 
            يوم الجودة العالمي هو مناسبة سنوية تسعى إلى زيادة الوعي بأهمية الجودة في جميع جوانب الحياة. يحتفل بهذا اليوم في الخميس الثاني من شهر نوفمبر كل عام، ويعتبر فرصة لتسليط الضوء على دور الجودة في تحسين الأداء والتميز في مختلف القطاعات. تتجلى أهمية يوم الجودة العالمي في عدة جوانب:  
            <br /><br />
            1. تعزيز الوعي : يسهم يوم الجودة العالمي في زيادة الوعي بمفاهيم الجودة وأهمية تطبيقها في جميع المجالات. من خلال الأنشطة والفعاليات، يتم تعريف الأفراد والمنظمات بأحدث التطورات وأفضل الممارسات في مجال الجودة.  
            <br /><br />
            2. تحفيز التحسين المستمر : يشجع هذا اليوم على تبني ثقافة التحسين المستمر، حيث يتم تبادل الأفكار والخبرات حول كيفية تحسين العمليات والخدمات بشكل دائم. هذا التحسين المستمر يؤدي إلى زيادة الكفاءة وتحقيق النتائج المرجوة بفعالية أكبر.  
            <br /><br />
            3. الابتكار والإبداع : يعتبر يوم الجودة العالمي فرصة لتشجيع الابتكار والإبداع في مجال الجودة. من خلال استعراض أحدث التقنيات والأساليب، يمكن للأفراد والمنظمات اكتشاف طرق جديدة لتحسين جودة منتجاتهم وخدماتهم.  
            <br /><br />
            4. تعزيز التنافسية : الجودة العالية تسهم في تعزيز القدرة التنافسية للمنظمات في الأسواق العالمية. يوم الجودة العالمي يساعد على نشر الوعي بأهمية الجودة في تحقيق التفوق التنافسي، مما يدفع الشركات إلى تحسين معاييرها ومنتجاتها لتلبية توقعات العملاء بشكل أفضل.  
            <br /><br />
            5. توحيد الجهود : يوفر هذا اليوم منصة لتوحيد الجهود العالمية في مجال الجودة. من خلال المؤتمرات والندوات الدولية، يمكن للخبراء والمهتمين بالجودة من مختلف البلدان تبادل الخبرات والمعرفة والتعاون لتحقيق أهداف مشتركة.  
            <br /><br />
            6. التأثير الإيجابي على المجتمع : تعزيز الجودة في مختلف القطاعات يؤدي إلى تحسين جودة الحياة بشكل عام. سواء كان ذلك في التعليم أو الرعاية الصحية أو الصناعة أو الخدمات، فإن التركيز على الجودة يسهم في تقديم خدمات ومنتجات أفضل للمجتمع.
          </p>
          </div>
          
          <h1>أنشطة وفعاليات ليوم الجودة </h1>
          <hr />
          <p className='about-event' >
           
الاحتفال بيوم الجودة يمكن أن يكون فرصة رائعة لتعزيز الوعي بأهمية الجودة وتحسين العمليات والخدمات في مختلف المجالات. هذه بعض الأفكار لتنظيم فعاليات وأنشطة مميزة في يوم الجودة: 
<br /><br />
 1. ورش عمل وتدريبات:  
 ورشة عمل تحسين الجودة: تنظيم ورش عمل لتعليم الموظفين أو المشاركين مبادئ وأدوات تحسين الجودة مثل  Lean Six Sigma ، إدارة الجودة الشاملة ( TQM )، وأدوات قياس الأداء.  
 <br />
 تدريبات تفاعلية: إعداد تدريبات تفاعلية حول كيفية تطبيق مفاهيم الجودة في العمل اليومي. 

  
 <br /><br />
 2. ندوات ومؤتمرات:  
 ندوة حول أهمية الجودة: دعوة خبراء ومتخصصين في مجال الجودة لإلقاء محاضرات ومشاركة تجاربهم وخبراتهم.  
 <br />
 مؤتمر عن أحدث تقنيات الجودة: استعراض أحدث الأدوات والتقنيات المستخدمة في تحسين الجودة.  

 
 <br /><br />
 3. مسابقات وجوائز:  
 مسابقة أفضل فكرة لتحسين الجودة: تشجيع الموظفين أو الطلاب على تقديم أفكار مبتكرة لتحسين العمليات والخدمات، مع تقديم جوائز للفائزين.  
 <br />
 جائزة التميز في الجودة: تكريم الأفراد أو الفرق الذين أظهروا التزامًا مميزًا بتحسين الجودة.  

 
 <br /><br />
 4. حملات توعية:  
 حملة توعوية عبر وسائل التواصل الاجتماعي: نشر مقالات وفيديوهات توعوية حول أهمية الجودة وكيفية تطبيقها في الحياة اليومية.  
 <br />
 مطبوعات ومنشورات: توزيع منشورات وكتيبات تحتوي على نصائح وإرشادات لتحسين الجودة.  

 
 <br /><br />
 5. زيارات ميدانية:  
 زيارة مصانع أو شركات نموذجية: تنظيم زيارات لمصانع أو شركات معروفة بتميزها في تطبيق معايير الجودة، للتعلم من تجاربهم العملية.  
 <br />
 جولات تعليمية: تقديم جولات تعليمية داخل المنظمة لشرح كيفية تطبيق معايير الجودة في مختلف الأقسام.  

 <br /><br />
 6. أنشطة تفاعلية:  
 لعبة تحسين الجودة: تصميم لعبة تفاعلية تساعد المشاركين على فهم مفاهيم الجودة بطريقة ممتعة.  
 <br />
 محاكاة سيناريوهات الجودة: إعداد سيناريوهات تفاعلية تحاكي مواقف حقيقية تواجه تحسين الجودة، ومناقشة الحلول الممكنة.
 <br /><br />
 7. مشاركة قصص نجاح:  
 قصص نجاح الجودة: دعوة موظفين أو عملاء لمشاركة قصص نجاحهم في تحسين الجودة، مما يلهم الآخرين ويعزز الفهم بأهمية الجودة.  

 فيديوهات توثيقية: إنتاج فيديوهات قصيرة تسلط الضوء على مشاريع تحسين الجودة الناجحة داخل المنظمة.
          </p>
          <p className='about-past' >
          تجارب وممارسات دولية في يوم الجودة العالمي  
يتم الاحتفال بيوم الجودة العالمي في مختلف دول العالم بطرق متنوعة ومبتكرة، مما يعكس الأهمية الكبيرة التي توليها المنظمات والمؤسسات لمفاهيم الجودة والتحسين المستمر. إليك بعض التجارب والممارسات الدولية في هذا اليوم:  
<br /><br />
1. الولايات المتحدة الأمريكية:  
 مؤتمرات الجودة: تنظم العديد من الجامعات والشركات الأمريكية مؤتمرات وندوات تسلط الضوء على أحدث التطورات في مجال إدارة الجودة. هذه المؤتمرات تستضيف خبراء من جميع أنحاء العالم لتبادل الأفكار والممارسات الفعالة.  
 <br />
ورش عمل تفاعلية: تقدم الشركات ورش عمل تفاعلية لموظفيها لتعزيز فهمهم لمفاهيم الجودة وكيفية تطبيقها في عملهم اليومي.  

 
<br /><br />
2. اليابان:  
حلقة الجودة: تعتبر اليابان رائدة في تطبيق مفهوم حلقات الجودة ( Quality Circles )، حيث يجتمع الموظفون بانتظام لمناقشة مشكلات الجودة واقتراح حلول عملية. يُعزز هذا النهج المشاركة الفعالة للموظفين في تحسين الجودة.  
<br />
 مسابقات الجودة: تُنظم مسابقات على مستوى الشركات لتحفيز الفرق على تقديم حلول إبداعية لمشكلات الجودة. 

  
 <br /><br />
3. ألمانيا:  
   أنشطة تعليمية: تُنظم الجامعات والمعاهد التعليمية في ألمانيا أنشطة تعليمية وورش عمل لطلاب الهندسة وإدارة الأعمال حول أهمية الجودة في العمليات الصناعية والإدارية.  
   <br />
   زيارات ميدانية: تقوم الشركات بتنظيم زيارات ميدانية لمواقع الإنتاج المثالية لتعريف الموظفين على أفضل الممارسات في الجودة.  

 
   <br /><br />
4. المملكة المتحدة:  
   جوائز التميز: تُقدم العديد من الجوائز مثل جائزة الجودة الوطنية ( British Quality Foundation ) التي تكرم الشركات والمنظمات التي أظهرت التزامًا فائقًا بمعايير الجودة.  
   <br />
   برامج تدريبية: تُطلق برامج تدريبية متقدمة للمديرين والموظفين حول إدارة الجودة وأدوات التحسين المستمر.  

 
   <br /><br />
5. الإمارات العربية المتحدة:  
   مبادرات حكومية: تطلق الحكومة الإماراتية مبادرات وطنية مثل "برنامج دبي للخدمة المتميزة" لتعزيز مفاهيم الجودة في القطاعين العام والخاص.  
   <br />
   منتديات الجودة: تُنظم منتديات وملتقيات تجمع قادة الفكر والخبراء لمناقشة التحديات والفرص في مجال الجودة.
          </p>
      </div>
    </div>
  )
}
 
export default About